/*
* 업무구분: 이벤트; 한글자 운세
* 화면 명: MSPSA073P
* 화면 설명: 상세운세보기
*/
<template>
  <ur-page-container class="" title="상세운세보기" :show-title="true" type="subpage">
    <!-- 한글자운세 전체 S -->
    <ur-box-container direction="column" alignV="start" align-h="center" componentid="" class="nw-qw-wrap nw-unse-wrap">
      <!-- 한글자운세 상세운세보기 S -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="nw-unse-detail"> 
        <ur-box-container alignV="start" alignH="center" componentid="ur_box_container_001" direction="column" class="unse-user-info">
          <div class="unse-user-txt">
              {{pThisYear}}년 운세 <br>
            <b>{{pCustInfo.custNm}}</b>님의 연간 총운입니다. 
          </div>
          <div class="unse-type-box">
            <ul>
              <li>
                <b class="num">{{pLuckData.BizNum}}%</b>
                <span class="txt">직장/사업</span>
              </li>
              <li>
                <b class="num">{{pLuckData.HealthNum}}%</b>
                <span class="txt">가정/건강</span>
              </li>
              <li>
                <b class="num">{{pLuckData.LoveNum}}%</b>
                <span class="txt">이성/관계</span>
              </li>
              <li>
                <b class="num">{{pLuckData.MoneyNum}}%</b>
                <span class="txt">재물</span>
              </li>
            </ul>
          </div>
        </ur-box-container>


          <mo-tab-box default-idx="1" class="ns-move-tab-box">
            <mo-tab-label idx="1">총평</mo-tab-label>
            <mo-tab-label idx="2">월별</mo-tab-label>
            <!-- 총평 START -->
            <mo-tab-content idx="1" >
              <p class="tab-con">{{pLuckData.Summary}}</p>
            </mo-tab-content>
            <!-- 총평 END -->
            <!-- 월별 START -->
            <mo-tab-content idx="2">
              <mo-tab-box default-idx="3" primary class="ns-tabs-box-o nw-tab-sub">
                <mo-tab-label idx="3">1월</mo-tab-label>
                <mo-tab-label idx="4">2월</mo-tab-label>
                <mo-tab-label idx="5">3월</mo-tab-label>
                <mo-tab-label idx="6">4월</mo-tab-label>
                <mo-tab-label idx="7">5월</mo-tab-label>
                <mo-tab-label idx="8">6월</mo-tab-label>
                <mo-tab-label idx="9">7월</mo-tab-label>
                <mo-tab-label idx="10">8월</mo-tab-label>
                <mo-tab-label idx="11">9월</mo-tab-label>
                <mo-tab-label idx="12">10월</mo-tab-label>
                <mo-tab-label idx="13">11월</mo-tab-label>
                <mo-tab-label idx="14">12월</mo-tab-label>
                <!-- 월별 운세내용 START -->
                <mo-tab-content idx="3" ><p class="tab-con">{{pLuckData.Mon[0].data}}</p></mo-tab-content>
                <mo-tab-content idx="4"><p class="tab-con">{{pLuckData.Mon[1].data}}</p></mo-tab-content>
                <mo-tab-content idx="5"><p class="tab-con"> {{pLuckData.Mon[2].data}}</p></mo-tab-content>
                <mo-tab-content idx="6"><p class="tab-con"> {{pLuckData.Mon[3].data}}</p></mo-tab-content>
                <mo-tab-content idx="7"><p class="tab-con"> {{pLuckData.Mon[4].data}}</p></mo-tab-content>
                <mo-tab-content idx="8"><p class="tab-con"> {{pLuckData.Mon[5].data}}</p></mo-tab-content>
                <mo-tab-content idx="9"><p class="tab-con"> {{pLuckData.Mon[6].data}}</p></mo-tab-content>
                <mo-tab-content idx="10"><p class="tab-con"> {{pLuckData.Mon[7].data}}</p></mo-tab-content>
                <mo-tab-content idx="11"><p class="tab-con"> {{pLuckData.Mon[8].data}}</p></mo-tab-content>
                <mo-tab-content idx="12"><p class="tab-con"> {{pLuckData.Mon[9].data}}</p></mo-tab-content>
                <mo-tab-content idx="13"><p class="tab-con">  {{pLuckData.Mon[10].data}}</p></mo-tab-content>
                <mo-tab-content idx="14"><p class="tab-con"> {{pLuckData.Mon[11].data}}</p></mo-tab-content>
                <!-- 월별 운세내용 END -->
              </mo-tab-box>
            </mo-tab-content>
            <!-- 월별 END -->
          </mo-tab-box>   
        
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_close">완료</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Print('send')">전송</mo-button>
             <mo-bottom-sheet ref="sendMethod" class="ns-bottom-sheet ns-style2">
                <div class="msp full">
                  <span class="fexTy1 fs17rem">전송방식을 선택하세요. </span>
                </div>
                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="">
                    <div class="relative-div">
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_SelCnsltInfo('oe')">이메일</mo-button>
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Print('print')">출력</mo-button>
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_SendSms()">문자</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>
              <!-- 예/아니오 bottom sheet START-->
              <mo-bottom-sheet ref="ozPrint" componentid="ur_box_container_002" class="ns-bottom-sheet ns-style2">
                <div class="msp full">
                  <span class="fexTy1 fs17rem pr24 pl24">{{printMsg}} </span>
                </div>
                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="">
                    <div class="relative-div">
                      <mo-button componentid="mo_button_007" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Print('close')">아니오</mo-button>
                      <mo-button componentid="mo_button_008" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SelCnsltInfo('op')">예</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>
              <!-- 예/아니오 bottom sheet END-->
          </div>        
        </ur-box-container>
       <!-- 한글자운세 상세운세보기 E -->  
      </ur-box-container>
       <!-- 한글자운세 상세운세보기 E -->
      <!-- 한글자운세 전체 E -->
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  import MSPSA074P from '@/ui/sa/MSPSA074P' // 이메일 전송
  import MSPCM128P from '@/ui/cm/MSPCM128P' // 문자발송  
  import MSPCM154P from '@/ui/cm/MSPCM154P' // 터치온발송
  import MSPCM191P from '@/ui/cm/MSPCM191P' // 이메일 수신고객 목록
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {
      'MSPSA074P': MSPSA074P,
      'MSPCM128P': MSPCM128P,
      'MSPCM154P': MSPCM154P,
      'MSPCM191P': MSPCM191P
    },
    props: {
      pCustInfo: {},
      pLuckData: {},
      pThisYear: ''
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      if (this.$bizUtil.isEmpty(this.pCustInfo.chnlCustId)) {
        this.printMsg = '발행서버로 인쇄 하시겠습니까?\n고객카드에 등록되지 않은 고객은 \n개인정보보호법상 [고객님]으로 \n발행됩니다.'
      } else {
        this.printMsg = '발행서버로 인쇄 하시겠습니까?'
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        printMsg: '',
        lv_CompletedPDFFileVOList: [], // PDF 생성 완료된 파일 정보를 저장하는 Array
        lv_BeMadePDFFileVOList: [],
        lv_IsSms: false,  // 문자 전송 여부
        lv_Popup128: {},  // 안내수신고객목록
        lv_Popup154: {},  // 터치온고객문자발송
        custListObj: {},  // 이메일, 문자 발송 시 정보
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_Print
       * 설명       : 지점발행 인쇄
       ******************************************************************************/
      fn_Print(val) {
        if (val === 'send') {
          this.$refs.sendMethod.open()
        } else if (val === 'print') {
          this.$refs.sendMethod.close()
          this.$refs.ozPrint.open()
        } else {
          this.$refs.ozPrint.close()
        }
      },
      /******************************************************************************
       * Function명 : fn_SelCnsltInfo
       * 설명       : 컨설턴트 정보 조회(전문 생성을 위해)
       ******************************************************************************/
      fn_SelCnsltInfo(val) {
        let lv_Vm = this
        if (lv_Vm.getStore('progress').getters.getState.isShow !== null && lv_Vm.getStore('progress').getters.getState.isShow === false) {
          lv_Vm.getStore('progress').getters.getState.isShow = true
        }
        let pParams = {
          userEno: this.getStore('userInfo').getters.getUserInfo.userId
        }
        const trnstId = 'txTSSBC20S3'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function(response) {
            // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                console.log(response.body)
                if (val === 'op') {
                  lv_Vm.fn_OzPrintService(response.body) // [보험거래문서디지털화PJT] OZ 지점발행 추가
                } else if (val === 'sms') {
                  lv_Vm.lv_IsSms = true                  
                  lv_Vm.fn_OzViewDetails(response.body) // OZ 문자 추가
                } else {
                  lv_Vm.lv_IsSms = false
                  lv_Vm.fn_OzViewDetails(response.body) // [보험거래문서디지털화PJT] OZ 이메일 추가
                }
              }
            }
          })
          .catch(function(error) {
            window.vue.error(error)
          })
      },     
      /******************************************************************************
       * Function명 : fn_SendSms
       * 설명       : 문자 발송 (시간 체크 후 문자 발송)
       ******************************************************************************/
      fn_SendSms() {
        const lv_Vm = this
        let realTiem = ''
        // 서버시간세팅
        this.$commonUtil.getServerTime().then( function (response) {
          realTiem = response
          realTiem = realTiem.substring(11)
          realTiem = realTiem.split(':')
          if (!_.isEmpty(realTiem)) {
            let strChk = 21 // 21 시 부터
            let endChk =  7 //  8 시 까지 (7시 59분 59초 전까지)
            if (realTiem[0] >= strChk || realTiem[0] <= endChk) {
              let alertMsg = '오후 9시부터 익일 오전 8시까지는 광고성 문자 전송이 불가합니다.'
              let alertObj = {msg: alertMsg}
              lv_Vm.$cmUtil.fn_OpenAlert(lv_Vm, alertObj)
            } else {
              lv_Vm.fn_SelCnsltInfo('sms')
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_OzPrintService
       * [보험거래문서디지털화PJT] OZ 출력 서비스 추가
       * 설명       : OZ 인쇄 서비스 호출
       ******************************************************************************/
      fn_OzPrintService(cnsltInfo) {
        // 통합영업자료ID 조회
        let t_Params = {
          untySlmtBasMclsfCd: 'Z7',
          untySlmtNm: '2023토정비결',
          duseScYn: 'N'
        }
        var lv_Vm = this
        let trnstId = 'txTSSSA72S1'
        const auth = 'S'
        let t_UntySlmtCdList = {}
        this.post(lv_Vm, t_Params, trnstId, auth)
          .then(function(response) {
            if (response.body !== null && response.body !== '') {
              if (response.body.untySlmtCdList !== null) {
                t_UntySlmtCdList = response.body.untySlmtCdList[0]
                console.log('>>> 1: ' + t_UntySlmtCdList.untySlmtId)
                console.log('>>> 2: ' + t_UntySlmtCdList.pblUcst.amount)
                console.log('>>> 3: ' + t_UntySlmtCdList.bnwUcst.amount)
                console.log('>>> 4: ' + t_UntySlmtCdList.colorUcst.amount)
                trnstId = 'txTSSSA11P8' // as-is txTSSSA11P4 -> to-be txTSSSA11P8
                let lv_NewFomName = t_UntySlmtCdList.srcFileNm
                let prtCntntSVO = {}
                prtCntntSVO.untySlmtId = t_UntySlmtCdList.untySlmtId // 통합영업자료ID
                prtCntntSVO.cnsltNo = cnsltInfo.userEno // 컨설턴트번호
                prtCntntSVO.fofNo = cnsltInfo.fofOrgNo // 지점조직번호
                if (cnsltInfo.pblSvrSno === null || cnsltInfo.pblSvrSno === '') {
                  prtCntntSVO.pblSvrSno = '0' // 발행일련번호
                } else {
                  prtCntntSVO.pblSvrSno = cnsltInfo.pblSvrSno // 발행일련번호
                }
                if (process.env.NODE_ENV === 'local') {
                  prtCntntSVO.pblSvrIp = '100.119.81.226' // 발행서버IP
                } else if (process.env.NODE_ENV === 'development') {
                  prtCntntSVO.pblSvrIp = '100.119.81.226' // 발행서버IP
                } else {
                  prtCntntSVO.pblSvrIp = cnsltInfo.pblSvrIp // 발행서버IP
                }
                prtCntntSVO.cnsltNm = cnsltInfo.userNm // 컨설턴트명
                prtCntntSVO.chnlScCd = cnsltInfo.chnlScCd // 채널구분코드
                prtCntntSVO.srcFileNm01 = lv_NewFomName // 원천화일명
                prtCntntSVO.bfPrntYn = 'N' // 양면인쇄여부
                prtCntntSVO.pblNos01 = 1 // 발행매수
                prtCntntSVO.doctStoreExnex = 'N' // PDF전환유무
                prtCntntSVO.prdtNm = t_UntySlmtCdList.untySlmtNm
                prtCntntSVO.pblUcst = t_UntySlmtCdList.pblUcst.amount // 발행단가
                prtCntntSVO.bnwUcst = t_UntySlmtCdList.bnwUcst.amount // 흑백단가
                prtCntntSVO.colorUcst = t_UntySlmtCdList.colorUcst.amount // 색상단가
                prtCntntSVO.trnsScCd = '20'

                console.log('■■■■■ MSPSA072P ■■■■■ 발행서버: ' + prtCntntSVO.pblSvrIp)

                if (lv_Vm.$bizUtil.isEmpty(lv_Vm.pCustInfo.chnlCustId)) {
                  prtCntntSVO.custNm = '고객' // 고객명
                  prtCntntSVO.mnCustNm = '고객' // 고객명
                  prtCntntSVO.chnlCustId = '' // 채널고객ID
                  prtCntntSVO.mnCustRrn = '' // 주민등록번호
                } else {
                  prtCntntSVO.custNm = lv_Vm.pCustInfo.custNm // 고객명
                  prtCntntSVO.mnCustNm = lv_Vm.pCustInfo.custNm // 고객명
                  prtCntntSVO.chnlCustId = lv_Vm.pCustInfo.chnlCustId // 채널고객ID
                  prtCntntSVO.mnCustRrn = lv_Vm.pCustInfo.knb // 주민등록번호
                }
                var iCCntntCustInfoVO01 = []
                var mOICCntntCustInfoVO = {}
                mOICCntntCustInfoVO.tlgmCntnt = decodeURIComponent(lv_Vm.fn_GetOzDDS(cnsltInfo, prtCntntSVO)) // 전문
                if (lv_Vm.$bizUtil.isEmpty(lv_Vm.pCustInfo.chnlCustId)) {
                  mOICCntntCustInfoVO.custNm = '고객' // 고객명
                  mOICCntntCustInfoVO.chnlCustId = '' // 채널고객ID
                  mOICCntntCustInfoVO.rrn = '' // 주민등록번호
                } else {
                  mOICCntntCustInfoVO.custNm = lv_Vm.pCustInfo.custNm // 고객명
                  mOICCntntCustInfoVO.chnlCustId = lv_Vm.pCustInfo.chnlCustId // 채널고객ID
                  mOICCntntCustInfoVO.rrn = lv_Vm.pCustInfo.knb // 주민등록번호
                }
                iCCntntCustInfoVO01.push(mOICCntntCustInfoVO)
                prtCntntSVO.ICCntntCustInfoVO01 = iCCntntCustInfoVO01
                lv_Vm.post(lv_Vm, prtCntntSVO, trnstId, auth)
                  .then(function(response) {
                    if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
                      // 서비스 후처리
                      if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                        // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                        lv_Vm.getStore('confirm').dispatch('ADD', '출력 되었습니다.')
                        lv_Vm.$refs.ozPrint.close()
                      } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
                        lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                      }
                    } else {
                      // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                      lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
                    }
                  })
                  .catch(function(error) {
                    window.vue.error(error)
                    // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
                    lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('CM')['error_message'])
                  })
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function(error) {
            window.vue.error(error)
          })
        // 통합영업자료ID 조회 Ends
      },
      /*******************************************************************************
       * Function명: fn_GetOzDDS
       * [보험거래문서디지털화PJT] 추가
       * 설명: OZ 전문 생성
       * { "FORM_ARY":[{"formId":""}],
       *   "DATA_ARY":[{"SingleValueMap":{}}],
       *   "META_ARY":[{}],
       *   "PBL_DFNT":{}
       * }
       *******************************************************************************/
      fn_GetOzDDS(cnsltInfo, prtCntntSVO) {
        var sData = ''
        // FORM_ARY
        // sData += '{"FORM_ARY":[{"formId":"/PQ/pvm7Z041_2024"}]'
        sData += '{"FORM_ARY":[{"formId":"/IC/pvm7Z041_2024"}]'
        sData += ',"DATA_ARY":[{"SingleValueMap":{'
        // 고객명
        if (this.$bizUtil.isEmpty(this.pCustInfo.chnlCustId)) {
          sData += '"FNAME":"고객"'
        } else {          
          sData += '"FNAME":"' + this.pCustInfo.custNm + '"'
        }
        // 토정비결 결과
        sData += ',"HMEAN":"' + this.pLuckData.HanJa + '"'
        sData += ',"HDATA":"' + this.pLuckData.HanCons + '"'
        sData += ',"GMON":"' + this.pLuckData.GoodMonth + ' 월"'
        sData += ',"BMON":"' + this.pLuckData.BadMonth + ' 월"'
        sData += ',"UNSE":"' + this.pLuckData.Summary + '"'
        sData += ',"NO1":"' + this.pLuckData.MoneyNum + '"'
        sData += ',"NO2":"' + this.pLuckData.BizNum + '"'
        sData += ',"NO3":"' + this.pLuckData.HealthNum + '"'
        sData += ',"NO4":"' + this.pLuckData.LoveNum + '"'
        sData += ',"M1":"' + this.pLuckData.Mon[0].data + '"'
        sData += ',"M2":"' + this.pLuckData.Mon[1].data + '"'
        sData += ',"M4":"' + this.pLuckData.Mon[2].data + '"'
        sData += ',"M3":"' + this.pLuckData.Mon[3].data + '"'
        sData += ',"M5":"' + this.pLuckData.Mon[4].data + '"'
        sData += ',"M6":"' + this.pLuckData.Mon[5].data + '"'
        sData += ',"M8":"' + this.pLuckData.Mon[6].data + '"'
        sData += ',"M7":"' + this.pLuckData.Mon[7].data + '"'
        sData += ',"M9":"' + this.pLuckData.Mon[8].data + '"'
        sData += ',"M10":"' + this.pLuckData.Mon[9].data + '"'
        sData += ',"M11":"' + this.pLuckData.Mon[10].data + '"'
        sData += ',"M12":"' + this.pLuckData.Mon[11].data + '"'
        /*******************************************************************************
         * 프린트 or 이메일 메뉴 선택 시 세팅하는 한글자이미지명
         * as-is는 프린트와 이메일 분기처리하여 구성이 상이한 전문을 생성
         * to-be는 동일한 전문을 구성하므로 분기처리 없이 로직 구현
         *******************************************************************************/
        sData += ',"HIMG":"P' + this.pLuckData.HanImg + '.jpg"'
        sData += ',"VUCH_NO":"' + cnsltInfo.orgNm + '"'
        if (!this.$bizUtil.isEmpty(cnsltInfo.celno.trim())) {
          sData += ',"Cnslt_CENO":"' + this.$bizUtil.telNoWithHyphen(cnsltInfo.celno) + '"'
        } else {
          sData += ',"Cnslt_CENO":"' + cnsltInfo.celno + '"'
        }
        if (!this.$bizUtil.isEmpty(cnsltInfo.emailAddr)) {
          sData += ',"Cnslt_EMAIL":"' + cnsltInfo.emailAddr + '@samsunglife.com"'
        } else {
          sData += ',"Cnslt_EMAIL":"' + cnsltInfo.emailAddr + '"'
        }
        sData += ',"Cnslt":"' + cnsltInfo.userNm + '"'
        var date = new Date()
        var stmon = date.getMonth() + 1
        sData += ',"FYEAR":"' + date.getFullYear() + '"'
        sData += ',"FMON":"' + stmon + '"'
        sData += ',"FDAY":"' + date.getDate() + '"'
        var sMdrt = cnsltInfo.mdrtScCd
        var sMdrtMsg = ''
        if (!this.$bizUtil.isEmpty(sMdrt)) {
          sMdrtMsg = 'MDRT(백만불 원탁회의)회원은 전 세계 최고의 권위를 자랑하는 생명보험전문가를 말합니다.'
          if (sMdrt === '01') {
            sMdrt = 'MDRT'
          } else if (sMdrt === '02') {
            sMdrt = 'MDRT(COT)'
          } else if (sMdrt === '03') {
            sMdrt = 'MDRT(TOT)'
          }
        } else {
          sMdrt = ''
        }
        sData += ',"MdrtScCd":"' + sMdrt + '"'
        sData += ',"MdrtMsg":"' + sMdrtMsg + '"'
        sData += '}}]' // DATA_ARY END
        sData += ',"META_ARY":[{"formname":"pvm7Z041_2024","title":"토정비결","color":"C","duplex":"1","WTMK_NO":""}]'
        if (prtCntntSVO.doctStoreExnex === 'Y') { // PDF 생성 시
          sData += ',"PBL_DFNT":{"fcname":"' + cnsltInfo.userNm + '","fccode":"' + cnsltInfo.userEno + '","title": "","merge": "Y","entplid": "","tbtpblno": "","channel": "' + cnsltInfo.chnlScCd + '","outgoing": ""}}'
        }
        console.log('## sData: ', sData)
        return sData
      },
      /*******************************************************************************
       * Function명: fn_OzViewDetails
       * [보험거래문서디지털화PJT] OZ PDF 생성 추가
       * 설명: PDF 생성요청
       *******************************************************************************/
      fn_OzViewDetails: async function(cnsltInfo) {
        let lv_Vm = this
        lv_Vm.lv_CompletedPDFFileVOList = []
        let pParams = {}
        let lv_NewFomName = 'pvm7Z041_2024'
        let dcFlmtCustInfoVO = {}
        // pParams.untySlmtId = lv_Vm.lv_SelectedSingleItem.untySlmtId // 통합영업자료ID
        pParams.fofNo = cnsltInfo.fofOrgNo // 지점조직번호
        pParams.pblSvrSno = cnsltInfo.pblSvrSno // 발행일련번호
        if (process.env.NODE_ENV === 'local') {
          pParams.pblSvrIp = '100.119.81.226' // 발행서버IP
        } else if (process.env.NODE_ENV === 'development') {
          pParams.pblSvrIp = '100.119.81.226' // 발행서버IP
        } else {
          pParams.pblSvrIp = cnsltInfo.pblSvrIp // 발행서버IP
        }
        pParams.cnsltNm = cnsltInfo.userNm // 컨설턴트명
        pParams.cnsltNo = cnsltInfo.userEno // 컨설턴트번호
        pParams.chnlScCd = cnsltInfo.chnlScCd // 채널구분코드
        pParams.trnsScCd = '05' // 발행전송구분코드 (05:PDF저장)
        pParams.pblUcst = '0' // 발행단가
        pParams.bnwUcst = '0' // 흑백단가
        pParams.colorUcst = '0' // 색상단가
        pParams.prdtNm = '토정비결' // 상품명
        pParams.srcFileNm01 = lv_NewFomName // 원천화일명
        pParams.srcFileNm02 = new Date().getTime() // PDF(저장시 : 통합영업자료ID)
        pParams.bfPrntYn = 'N' // 양면인쇄여부
        pParams.pblNos01 = 1 // 발행매수
        pParams.doctStoreExnex = 'Y' // PDF전환유무
        // dcFlmtCustInfoVO.tlgmCntnt = 'WSDATA-N' + decodeURIComponent(this.fn_GetDDS(cnsltInfo)) // 전문
        dcFlmtCustInfoVO.tlgmCntnt = decodeURIComponent(this.fn_GetOzDDS(cnsltInfo, pParams)) // 전문
        dcFlmtCustInfoVO.custNm = '' // 고객명
        dcFlmtCustInfoVO.chnlCustId = '' // 채널고객ID
        dcFlmtCustInfoVO.rrn = '' // 주민등록번호
        let dcFlmtCustInfoVOList = []
        dcFlmtCustInfoVOList.push(dcFlmtCustInfoVO)
        pParams.dCFmlmtCustInfoVO01 = dcFlmtCustInfoVOList
        const trnstId = 'txTSSSA22P1'
        const auth = 'T'
        await this.post(lv_Vm, pParams, trnstId, auth)
          .then(function(response) {
            // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                let sADCFmlmtSVO = response.body
                if (sADCFmlmtSVO.srcFileNm01 !== null && sADCFmlmtSVO.srcFileNm01.length > 0) {
                  console.log('■■■■■ TSSSA072P ■■■■■ PDF 파일 생성(fn_OzViewDetails) sADCFmlmtSVO.srcFileNm01=' + sADCFmlmtSVO.srcFileNm01)
                  sADCFmlmtSVO.checkTryCount = 1
                  lv_Vm.lv_BeMadePDFFileVOList.push(sADCFmlmtSVO)
                  lv_Vm.fn_ChkDocsTrfo(sADCFmlmtSVO)
                }
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                if (lv_Vm.getStore('progress').getters.getState.isShow !== null && lv_Vm.getStore('progress').getters.getState.isShow === true) {
                  lv_Vm.getStore('progress').getters.getState.isShow = false
                }
                if (response.msgComm.msgCd !== null && response.msgComm.msgCd.length > 0) {
                  lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                }
              }
            }
          })
          .catch(function(error) {
            // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
            if (lv_Vm.getStore('progress').getters.getState.isShow !== null && lv_Vm.getStore('progress').getters.getState.isShow === true) {
              lv_Vm.getStore('progress').getters.getState.isShow = false
            }
            window.vue.error(error)
          })
        lv_Vm.lv_BeMadePDFFileVOList.forEach((item, index) => {
          console.log('■■■■■ TSSSA072P ■■■■■ PDF 파일 생성(fn_OzViewDetails) lv_Vm.lv_BeMadePDFFileVOList[' + index + '].srcFileNm01=' + item.srcFileNm01)
        })
        console.log('■■■■■ TSSSA072P ■■■■■ PDF 파일 생성(fn_OzViewDetails) END')
      },
      /*******************************************************************************
       * Function명: fn_ChkDocsTrfo
       * 설명: PDF 변환 요청
       *******************************************************************************/
       fn_ChkDocsTrfo(pVO) {
        console.log('■■■■■ TSSSA072P ■■■■■ fn_ChkDocsTrfo START... ☞ ' + new Date().getTime())
        let lv_Vm = this
        const trnstId = 'txTSSSA22P2'
        const auth = 'T'
        let sParams = {}
        sParams.moblBusnScCd = 'CM'
        sParams.eltrnDoctScCd = '07'
        sParams.eltrnDoctId = pVO.srcFileNm01
        sParams.beMadePDFFileVO = pVO
        this.post(lv_Vm, sParams, trnstId, auth)
          .then(function(response) {
            console.log('■■■■■ TSSSA072P ■■■■■ fn_ChkDocsTrfo sParams.beMadePDFFileVO.untySlmtId=' + sParams.beMadePDFFileVO.untySlmtId)
            // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
            lv_Vm.getStore('progress').getters.getState.isShow = true
            // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                let returnObj = response.body
                if (returnObj.trfoCmpltYn != null && returnObj.trfoCmpltYn === 'Y') {
                  lv_Vm.lv_CompletedPDFFileVOList.push(returnObj)
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  lv_Vm.getStore('progress').getters.getState.isShow = false                  
                  if (lv_Vm.lv_IsSms){
                    // lv_Vm.fn_OpenMSPSA075P()
                    lv_Vm.fn_ChangePdfToJpeg(pVO)
                  } else {                    
                    lv_Vm.fn_EmailClick()
                  }
                } else {
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  lv_Vm.getStore('progress').getters.getState.isShow = true
                  if (sParams.beMadePDFFileVO.checkTryCount >= 20) {
                    lv_Vm.lv_CompletedPDFFileVOList.push(returnObj)
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_Vm.getStore('progress').getters.getState.isShow = false
                  } else {
                    setTimeout(function() {
                      sParams.beMadePDFFileVO.checkTryCount++
                      lv_Vm.fn_ChkDocsTrfo(sParams.beMadePDFFileVO)
                    }, 2000)
                  }
                }
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                lv_Vm.getStore('progress').getters.getState.isShow = false
                if (response.msgComm.msgCd !== null && response.msgComm.msgCd.length > 0) {
                  lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                }
              }
            }
          })
          .catch(function(error) {
            // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
            lv_Vm.getStore('progress').getters.getState.isShow = false
            window.vue.error(error)
          })
        console.log('■■■■■ TSSSA072P ■■■■■ fn_ChkDocsTrfo END... ☞ ' + new Date().getTime())
      },      
      /******************************************************************************
        * Function명 : fn_ChangePdfToJpeg
        * 설명       : 이미지 변환
      ******************************************************************************/
      fn_ChangePdfToJpeg (pVO) {
        let lv_Vm = this
        let pParams = {}                        
        pParams.eltrnDoctFileNm = pVO.srcFileNm01
        let trnstId = 'txTSSSA22P6'
        this.post(this, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.body !== '') {              
              let returnObj = response.body
              if (returnObj.trfoCmpltYn != null && returnObj.trfoCmpltYn === 'Y') {
                lv_Vm.fn_SelDocImg(returnObj.eltrnDoctFileNm)
                // lv_Vm.getStore('toast').dispatch('ADD', '이미지 변환이 완료되었습니다.')
              } else {                
                lv_Vm.getStore('toast').dispatch('ADD', '이미지 변환 실패하였습니다.')
              }
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })     
      },
      /******************************************************************************
        * Function명 : fn_SelDocImg
        * 설명       : 이미지 조회 
      ******************************************************************************/
      fn_SelDocImg (fileNm) {
        let lv_Vm = this
        let pParams = {}                
        pParams.imgFileNm = fileNm
        let trnstId = 'txTSSSA22P7'
        this.post(this, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              let result = response.body              
              if (result.isExistsImage === 'Y') {
                lv_Vm.fn_OpenMSPCM128P(result.imgData)
                // lv_Vm.getStore('toast').dispatch('ADD', 'Base64 변환이 완료되었습니다.')
              } else if (result.isExistsImage === 'N') {                
                lv_Vm.getStore('toast').dispatch('ADD', '이미지 변환 실패하였습니다.')
              } 
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })     
      },
      /******************************************************************************
       * Function명 : fn_OpenMSPSA074P
       * 설명       : 이메일 발송 팝업
       ******************************************************************************/
      fn_OpenMSPSA074P(pCustInfo, pFileList, pTitle, pText) {        
        this.lv_Popup074 = this.$bottomModal.open(MSPSA074P, {
          properties: {
            pPage: 'MSPSA074P',
            pIsBackKeyListen: true,
            pCustInfo: pCustInfo,
            pAttachedFileList: pFileList,
            pSrnId: 'MSPSA070M',
            pTitle: pTitle,
            pText:  pText            
          },
          listeners: {
            closeDivision: () => {
              console.log('closeDivision')
              this.$bottomModal.close(this.lv_Popup074)
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_EmailClick
       * 설명       : 이메일 연동
       ******************************************************************************/
      fn_EmailClick () {
        let lv_Vm = this
        let custListObj = [{targetType: 'e', parentsViewId: 'MSPSA073P', custList: [lv_Vm.pCustInfo]}]        
        let selectPDFfile = lv_Vm.lv_CompletedPDFFileVOList        
        let cnsltNm = lv_Vm.getStore('userInfo').getters.getUserInfo.userNm // 컨설턴트명2rmfdkdksl
        let title = '[광고] 삼성생명 한글자운세'        
        let text = '(광고)삼성생명\n' +
                    '안녕하세요\n' +
                    '2024년 갑진년 한 글자 운세를\n' +
                    '보내드립니다.\n' +
                    '건강하고 행운 가득한\n' +
                    '2024년이 되시길 소망합니다.\n' +
                    '감사합니다.\n\n' +
                    '삼성생명 ' + cnsltNm + ' 컨설턴트\n'        

        // 팝업 기본 옵션 
        let popup191 = lv_Vm.$bottomModal.open(MSPCM191P, {
          properties: {
            pChnlCustIds: custListObj,// 발송 고객 리스트
            pPDFfile: selectPDFfile, //첨부파일 리스트
            pTitle: title, // 메일 제목
            pText: text // 메일 본문 내용            
          },
          listeners: {
            // 단순 팝업 종료
            onPopupCancel: () => {
              lv_Vm.$bottomModal.close(popup191)
            },
            onNextService: async (pData, selectPDFfile, title, text) => {
              await lv_Vm.$bottomModal.close(popup191)
              if(pData && pData.length > 0){
                let custInfo = pData[0]
                custInfo.emailAddr = custInfo.custEmailAddr
                await lv_Vm.fn_OpenMSPSA074P(custInfo, selectPDFfile, title, text)
              }
            }
          }
        })
      },

      
      /*********************************************************
      * Function명: fn_OpenMSPCM128P
      * 설명: 수신고객목록 팝업오픈 (문자발송)
      * Params: N/A
      * Return: N/A
      *********************************************************/
      fn_OpenMSPCM128P: _.debounce(function (imgData) {
        // console.log('fn_OpenMSPCM128P >', imgData)
        let lv_Vm = this        
        lv_Vm.lv_Popup128 = lv_Vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: [ {targetType: 's', parentsViewId: 'MSPSA073P', custList: [lv_Vm.pCustInfo]} ], // param셋팅          
          pImgSrc: imgData
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupClose: (pData) => {             
            lv_Vm.$bottomModal.close(lv_Vm.lv_Popup128)
          },
          // 단순 팝업 종료
          onPopupCancel: () => {            
            lv_Vm.$bottomModal.close(lv_Vm.lv_Popup128)
          },         
          // 터치온 발송 프로세스 async
          onNextService: (pData) => {              
              lv_Vm.$bottomModal.close(lv_Vm.lv_Popup128)
              lv_Vm.custListObj = []
              lv_Vm.custListObj = pData.rtnData
            // 터치온 팝업 오픈(MSPCM154P)
              lv_Vm.fn_OpenMSPCM154P()
          }
        }
        })    
      }, 300), 
      /*********************************************************
       * Function명: fn_OpenMSPCM154P
       * 설명: 터치On 문자발송 팝업 오픈
       * 
       *********************************************************/
      fn_OpenMSPCM154P: _.debounce(function () {
        let lv_Vm = this
        lv_Vm.lv_Popup154 = lv_Vm.$bottomModal.open(MSPCM154P, {
         properties: {
            pPage: lv_Vm.custListObj.parentsViewId,                   // 모화면 페이지명
            pSmsTargetCustList: lv_Vm.custListObj.smsTargetCustList,  // 수신고객리스트
            pMsg: lv_Vm.custListObj.msg,                              // 선택된 상용구
            pIsNotEditable: lv_Vm.custListObj.isNotEditable           // 메시지 수정가능여부
          },
          listeners: {
            onPopupClose: (pData) => { 
              if (pData.str === 'confirm') {
                lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
              }
              // 모달 닫기
              lv_Vm.$bottomModal.close(lv_Vm.lv_Popup154)
            }
          }
        })
        } , 300), 
      fn_close () {
        this.$emit('closeDivision')
      }
    }
  };
</script>
<style scoped>
.ur-box-container.nw-qw-wrap {
padding: 16px 0;
}
</style>