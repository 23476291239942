/*
* 업무구분: 이벤트 &gt; 한글자 운세
* 화면 명: MSPSA072P
* 화면 설명: 한글자 운세 요약
*/
<template>
  <ur-page-container title="한글자 운세 요약" :show-title="true" type="subpage">
    <!-- 한글자운세 전체 S -->
    <ur-box-container direction="column" align-v="start"  align-h="center" componentid="" class="nw-qw-wrap nw-unse-wrap pal0">
      <!-- 한글자운세 요약 S -->
      <ur-box-container align-v="start" componentid="" direction="column" class="nw-qw-main nw-unse-info">
        <strong class="unse-tit">
          <b>{{pCustInfo.custNm}}</b>님의<br> {{pThisYear}}년을 대표하는 한자는<br> <b>"{{pLuckData.HanJa}}"</b>입니다.
        </strong>
        <img :src="pLuckData.lettrImg" :alt="pLuckData.HanJa" class="img-font">
        <div class="month-box">
          <span class="month-info">
            <span class="tit">좋은달</span>
            <b class="month">{{pLuckData.GoodMonth}}</b>
          </span>
          <span class="month-info">
            <span class="tit">주의할 달</span>
            <b class="month ty1">{{pLuckData.BadMonth}}</b>
          </span>
        </div>
        <div class="month-detail-box mb50">
          <p>{{pLuckData.HanCons}}</p>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button shape="border" size="medium" class="ns-btn-round blue" color="orange" @click="fn_OpenMSPSA073P">상세운세보기</mo-button>
          </div> 
        </ur-box-container>
      </ur-box-container>
      <!-- 한글자운세 요약 E -->
    </ur-box-container>
    <!-- 한글자운세 전체 E -->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPSA073P from '@/ui/sa/MSPSA073P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {
      'MSPSA073P': MSPSA073P,
    },
    props: {
      pCustInfo: {},
      pLuckData: {},
      pThisYear: ''
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('MSPSA072P pCustInfo', this.pCustInfo)
      console.log('MSPSA072P pLuckData', this.pLuckData)
      this.fn_SetLuckData()
    },
    mounted() {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {};
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_OpenMSPSA072D
       * 설명       : 한글자 운세 요약
       ******************************************************************************/
      fn_OpenMSPSA073P () {
        this.lv_Popup073 = this.$bottomModal.open(MSPSA073P, {
          properties: {
          pPage: 'MSPSA073P',
          pIsBackKeyListen: true,
          pCustInfo: this.pCustInfo,
          pLuckData: this.pLuckData,
          pThisYear: this.pThisYear,
          pSrnId: 'MSPSA072D'
        },
        listeners: {
          closeDivision: () => {
            console.log('closeDivision MSPSA073P')
            this.$emit('closeDivision')
            this.$bottomModal.close(this.lv_Popup073)
          }
          }
        })
      },
      /*******************************************************************************
      * Function명: fn_SetLuckData
      * 설명: 받아온 운세 관련 데이터를 세팅한다
      *******************************************************************************/
      fn_SetLuckData () {
        this.pLuckData.lettrImg = require('@/assets/img/chineseCharImg/P' + this.pLuckData.HanImg + '.jpg')
        console.log(this.pLuckData.lettrImg)
      }
    }
  };
</script>